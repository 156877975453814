












































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import View16 from '@carbon/icons-vue/es/view/16'
import TablePagination from '@/partials/tables/Pagination.vue'

import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    View16
  }
})
export default class ExamsFinishedIndex extends Mixins(PermissionsMixin) {
  filteredData: Array<ExamSchedule> = []
  exams: Array<ExamSchedule> = []

  examsTotal = 0

  selectedExam: ExamSchedule = {} as ExamSchedule
  deleteModalIsOpened = false

  created () {
    this.fetchExams()
  }

  fetchExams (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('exam/schedule?status=complete', {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.exams = camelCaseKeys(response.data.data, { deep: true })
        this.examsTotal = response.data.total
      })
  }

  displayExamType (type: string) {
    const name: Record<string, string> = {
      test: 'Prova',
      practice: 'Simulado',
      single: 'Exercício Avulso'
    }
    return name[type]
  }

  search () {
    console.log('Busca')
  }

  onPageChange (pagination: Pagination) {
    this.fetchExams(pagination.page, pagination.length)
  }
}
